import React, {useState} from 'react';
import {
    Row, Form, Button, Input,
    Tabs,
    notification
} from "antd";
import Users from "../../logic/users";
import Profile from "./DetailTabPanes/Profile";
import Accounts from "./DetailTabPanes/Accounts";
import RecentTransactions from './DetailTabPanes/RecentTransactions';

const {TabPane} = Tabs;
  
function CustomerDetail(props) {
    const [fetching, setFetching] = useState(false);
    // const [user, setUser] = useState({
    //     profile: {
    //         name: "Esau Lwanga",
    //         dob: "1995-08-12",
    //         email: "lemndev@gmail.com",
    //         phone: "256706087495",
    //         address: "Kira",
    //         no_accounts: 3,
    //         no_transactions: 50,
    //         average_transaction_amount: 50000,
    //     },
    // });
    const [ userProfile, setUserProfile ] = useState(null);
    const [ accounts, setAccounts ] = useState([]);
    const [ transactions, setTransactions ] = useState([]);

    const submitSearch = async (values) => {
        setFetching(true)
        const users = new Users(props, values.msisdn);
        // const results = await users.getDetails({msisdn: values.msisdn, detail: "profile"})
        const results = await users.getDetails({msisdn: values.msisdn})
        if (results.status === 200){
            notification.success({description: results.message})

            // get the accounts
            // const accounts = await users.getDetails({id: results.data._id, detail: "accounts"})
            // setAccounts(accounts.data)

            // // get the transactions
            // const transactions = await users.getDetails({id: results.data._id, detail: "recent-transactions"})
            // setTransactions(transactions.data)

            setUserProfile(results.data.profile)
            setAccounts(results.data.accounts)
            setTransactions(results.data.recent_transactions)
        } else {
            notification.error({description: results.message})
        }
        // setUserProfile(results.data)
        setFetching(false)
    }

    
    return (
        <React.Fragment>
            
            <Row>
                <Form onFinish={submitSearch} layout="inline" >
                    <Form.Item
                        label="Customer Phone No"
                        name="msisdn"
                        rules={[
                        {
                            required: true,
                            message: "Please enter the phone number of the customer",
                        },
                        ]}
                    >
                        <Input style={{width: '100%'}} placeholder="e.g. 256706087495" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={fetching}>
                            Search
                        </Button>
                    </Form.Item>
                </Form>
            </Row>

            {/* tabs for the various sections */}

            <Row style={{marginTop: '20px'}}>
            <Tabs style={{width: '100%'}}>
                <TabPane tab="Profile" key="profile" >
                    {
                        userProfile ? <Profile customer={userProfile} /> : <p>No user found</p>
                    }
                </TabPane>

                <TabPane tab="Accounts" key="accounts">
                    <Accounts accounts={accounts} />
                </TabPane>

                <TabPane tab="Last 50 Transactions" key="transactions">
                    <RecentTransactions transactions={transactions} />
                </TabPane>

                <TabPane tab="Groups" key="Groups">

                </TabPane>
            </Tabs>
            </Row>
            
        </React.Fragment>
    );
  }
  
export default CustomerDetail;
