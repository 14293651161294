import store from '../store/store';
import { callApi } from "../apiIntegration";
import { notification } from 'antd';
import { setOverview } from "../store/reducers/overview";


class Reports {
    constructor(props, data, ){
        this.props = props;
        this.data = data;
        this.notificationStatus = {
            200: "success",
            400: "error",
            401: "error"
        };
    }
    /**
     * get the dashboard details
     */
    async getDashboard(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: {},
            endpoint: "/api/v1/admin-functions/dashboard/",
            contentType: "application/json",
          })

        if (apiResponse.status === 200){
            store.dispatch(setOverview(apiResponse))
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }
    

    /**
     * Gets all the accounts to consider
     */
    // getAll(){
    //     callApi({
    //         requireAuth: true,
    //         method: "GET",
    //         body: JSON.stringify({}),
    //         endpoint: "api/v1/accounts/list",
    //         contentType: "application/json",
    //     })
    //     .then(async (res) => {
    //         await store.dispatch(setAccounts(res.data));
    //     })
    //     .catch((err)=>{
    //         notification.error({
    //             description: "An error occurred while retrieving your accounts",
    //         })
    //     })  
    // }

    /**
     * Submits changes to the details of an account
     */
    // edit(){
    //     callApi({
    //         requireAuth: true,
    //         method: "PUT",
    //         body: JSON.stringify(this.data),
    //         endpoint: "api/v1/accounts/",
    //         contentType: "application/json",
    //     })
    //     .then(async (res) => {
    //         notification[this.notificationStatus[res.status]]({
    //             description: res.message
    //         })
    //         this.getAll();
    //     })
    //     .catch((err)=>{
    //         console.log(err);
    //         notification.error({
    //             description: "An error occurred while updating the account details",
    //         })
    //     })  
    // }
}


export default Reports;