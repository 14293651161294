import React from 'react';
import {Table, Button} from 'antd';
import ManualTransactionModal from '../../ActionModals/ManualTransaction';

const columns = [
    { title: 'Account', dataIndex: 'account_type', key: 'account_type' },
    { title: 'Created', dataIndex: 'time_created', key: 'time_created', render: (time_created) => <span>{new Date(time_created).toLocaleString()}</span> },
    { title: 'Saving Period (mths)', dataIndex: 'saving_period', key: 'saving_eriod', },
    { title: 'Maturity', dataIndex: 'maturity_date', key: 'maturity_date', render: (maturity_date) => <span>{new Date(maturity_date).toLocaleDateString()}</span> },
    { title: 'Actual Balance', dataIndex: 'actual_balance', key: 'actual_balance', render: (actual_balance) => <span>{`UGX ${Number(actual_balance).toLocaleString()}`}</span> },
    { title: 'Available Balance', dataIndex: 'available_balance', key: 'available_balance', render: (available_balance) => <span>{`UGX ${Number(available_balance).toLocaleString()}`}</span> },
    {title: 'Actions', key: 'action', render: (text, record) => 
        <React.Fragment>
            <ManualTransactionModal action="Deposit" accountType={record.account_type} accountId={record._id} />
            <ManualTransactionModal action="Withdraw" accountType={record.account_type} accountId={record._id}/>
        <Button size="small" style={{margin: '2px'}} danger>Lock</Button>
        </React.Fragment>
    }
]

const AccountsTable = ({ accounts }) => {
    return (
        <Table
            dataSource={accounts}
            columns={columns}
            pagination={false}
        />
    );
};

export default AccountsTable;
