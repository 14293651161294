import store from '../store/store';
import { callApi } from "../apiIntegration";
import { notification } from 'antd';
import { setUsers } from "../store/reducers/users";


class Users {
    constructor(data, ){
        this.data = data;
        this.notificationStatus = {
            200: "success",
            400: "error",
            401: "error"
        };
    }

    /**
     * filter for the list of users
     */
    async list(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: {},
            endpoint: `/api/v1/users/filter/${this.data}`,
            contentType: "application/json",
          })
          console.log(apiResponse)
        if (apiResponse.status === 200){
            store.dispatch(setUsers(apiResponse))
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }

    /**
     * Get details of a user
     */
    async getDetails(filterData){
        const {msisdn} = filterData;
        let endpoint =  `/api/v1/admin-functions/find-customer/?msisdn=${msisdn}`
        // {{mainframe_localhost}}/api/v1/admin-functions/find-customer/?msisdn=256706087495
        // if (msisdn){
        //     endpoint += `&msisdn=${msisdn}`
        // }
        // if (id){
        //     endpoint += `&id=${id}`
        // }
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: {},
            endpoint: endpoint,
            contentType: "application/json",
          })
        return apiResponse
    }

    /**
     * Reset the pin of a user
     */
    async resetPin(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "POST",
            body: JSON.stringify(this.data),
            endpoint: `/api/v1/users/reset-pin/`,
            contentType: "application/json",
          })
          notification[this.notificationStatus[apiResponse.status]]({
            description: apiResponse.message
        })
        return apiResponse
    }
}

export default Users;